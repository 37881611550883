<template>
    <v-container
        id="instruments"
        fluid
        tag="section"
    >
        <v-row justify="center">
            <v-col
                cols="12"
                lg="9"
                xl="7"
                class="block-media-lk"
            >
                <base-material-card
                    color="warning"
                    class="px-sm-8 px-md-16 px-2 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Обзор инструментов, стоимость, скидки
                        </div>
                    </template>

                    <v-card-text
                        class="mt-10 mb-10 card-media-lk"
                    >
                        <p class="text-center mb-10">
                            <v-btn color="success mr-0" @click="scrollToMe">
                                ТАРИФЫ
                            </v-btn>
                        </p>                        

                        <h2 class="h2-tarif mt-15 mb-10" id="discount">Тарифы</h2>
                        <p class="p-dostup">Доступ к 21 инструментам:</p>
                        <v-row class="row-instruments">
                          <v-col cols="12" sm="6"> 
                            <p class="p-dostup-check">
                                  <img src="/img/check-black.png" />
                                  <span>Страница-визитка</span>
                              </p>
                              <p class="p-dostup-check">
                                  <img src="/img/check-black.png" />
                                  <span>Бизнес-презентация</span>
                              </p>
                              <p class="p-dostup-check">
                                  <img src="/img/check-black.png" />
                                  <span>Виртуальный наставник</span>
                              </p> 
                              <p class="p-dostup-check">
                                  <img src="/img/check-black.png" />
                                  <span>Лид-бот</span>
                              </p>
                              <p class="p-dostup-check">
                                  <img src="/img/check-black.png" />
                                  <span>Тест по бизнесу</span>
                              </p>
                              <p class="p-dostup-check">
                                <img src="/img/check-black.png" />
                                <span>Рост команды</span>
                              </p>
                              <p class="p-dostup-check">
                                  <img src="/img/check-black.png" />
                                  <span>Большой ЛТО</span>
                              </p>
                              <p class="p-dostup-check">
                                  <img src="/img/check-black.png" />
                                  <span>Варианты стартов</span>
                              </p> 
                              <p class="p-dostup-check">
                                  <img src="/img/check-black.png" />
                                  <span>Сайт по запуску и активации</span>
                              </p>              
                          </v-col>
                          <v-col cols="12" sm="6">
                            <p class="p-dostup-check">
                                <img src="/img/check-black.png" />
                                <span>Тест по здоровью</span>
                            </p>
                            <p class="p-dostup-check">
                              <img src="/img/check-black.png" />
                              <span>Гели Алое Вера</span>
                            </p>
                            <p class="p-dostup-check">
                                <img src="/img/check-black.png" />
                                <span>Капля 5 в 1</span>
                            </p> 
                            <p class="p-dostup-check">
                                <img src="/img/check-black.png" />
                                <span>Zeitgard</span>
                            </p>  
                            <p class="p-dostup-check">
                                <img src="/img/check-black.png" />
                                <span>Алое Вера Box</span>
                            </p>                            
                            <p class="p-dostup-check">
                                <img src="/img/check-black.png" />
                                <span>Подарки</span>
                            </p>
                            <p class="p-dostup-check">
                                <img src="/img/check-black.png" />
                                <span>Аромамасла</span>
                            </p>
                            <p class="p-dostup-check">
                                <img src="/img/check-black.png" />
                                <span>Рекомендации по заболеваниям</span>
                            </p>      
                            <p class="p-dostup-check">
                                <img src="/img/check-black.png" />
                                <span>Масштабирование онлайн</span>
                            </p>    
                            <p class="p-dostup-check">
                                <img src="/img/check-black.png" />
                                <span>Марафон ЗОЖ и коррекции веса StarHealth</span>
                            </p> 
                          </v-col>
                        </v-row>

                        <v-row class="mt-5">
                          <v-col cols="12" sm="3" class="block-one-year">
                                <img class="skobka-left" src="/img/main-black/skobka-left.png" />
                                <p class="p-one-year">1 год</p>                        
                                <p class="p-cost-one-year-old">
                                    <img class="old-price-img" src="/img/new_main/old-price.png" />
                                    12 000 &#8381;
                                </p>
                                <p class="p-cost-one-year">6 000 &#8381;</p>
                                <img class="skobka-right" src="/img/main-black/skobka-right.png" />
                                <p class="p-samiy">Самый выгодный</p>
                                <p class="price-500">500 руб в месяц</p>
                                <img class="accent-img" src="/img/main-black/accent-red.png" />
                                <v-btn
                                    v-if="profileData.lr_number"
                                    small
                                    class="btn-main mr-0 mt-3"
                                    rounded
                                    color="#5a7e37"
                                    href="https://payform.ru/pa24qKT/"
                                    target="_blanc"
                                >Оплатить</v-btn>
                                <p class="p-red">
                                    При оплате важно ввести email,<br />
                                    указанный при регистрации
                                </p>
                            </v-col>
                            <v-col cols="12" sm="3" style="text-align: center;">
                                <p class="p-oplata-mes">6 мес</p>
                                <p class="p-price-oplata-old">
                                    <img class="old-price-img-oplata" src="/img/new_main/old-price.png" />
                                    6 000 &#8381;
                                </p>
                                <p class="p-price-oplata">3 500 &#8381;</p>
                                <p class="p-oplata-vigoda">
                                    Твоя выгода <br />
                                    2 500 &#8381;
                                </p>
                                <v-btn
                                    v-if="profileData.lr_number"
                                    small
                                    class="btn-main mr-0"
                                    rounded
                                    color="#5a7e37"
                                    href="https://payform.ru/nd24qJS/"
                                    target="_blanc"
                                >Оплатить</v-btn>
                                <p class="p-red">
                                    При оплате важно ввести email,
                                    указанный при регистрации
                                </p>
                            </v-col>
                            
                            <v-col cols="12" sm="3" style="text-align: center;">
                                <p class="p-oplata-mes">3 мес</p>
                                <p class="p-price-oplata-old">
                                    <img class="old-price-img-oplata" src="/img/new_main/old-price.png" />
                                    3 000 &#8381;
                                </p>
                                <p class="p-price-oplata">2 000 &#8381;</p>
                                <p class="p-oplata-vigoda">
                                    Твоя выгода <br />
                                    1 000 &#8381;
                                </p>
                                <v-btn
                                    v-if="profileData.lr_number"
                                    small
                                    class="btn-main mr-0"
                                    rounded color="#5a7e37"
                                    href="https://payform.ru/lg24qIR/"
                                    target="_blanc"
                                >Оплатить</v-btn>
                                <p class="p-red">
                                    При оплате важно ввести email,
                                    указанный при регистрации
                                </p>
                            </v-col>

                            <v-col cols="12" sm="3" style="text-align: center;">
                                <p class="p-oplata-mes">1 мес</p>
                                <p class="p-price-oplata-1000">1 000 &#8381;</p>
                                <p class="p-oplata-vigoda mob">
                                    &nbsp; <br />
                                    &nbsp;
                                </p>
                                <v-btn
                                    v-if="profileData.lr_number"
                                    small
                                    class="btn-main mr-0"
                                    rounded
                                    color="#5a7e37"
                                    href="https://payform.ru/k024qI4/"
                                    target="_blanc"
                                >Оплатить</v-btn>
                                <p class="p-red">
                                    При оплате важно ввести email,
                                    указанный при регистрации
                                </p>
                            </v-col>
                        </v-row>

                        <p class="p-attention mt-10">! Внимание !</p>           
                        <p class="p-open-dostup">При оплате важно ввести email, указанный при регистрации</p>
                        <p class="p-open-dostup">По всем вопросам обращайтесь в техподдержку</p>
                        <v-img style="margin: 0 auto;" src="/img/new_main/double-arrows-bottom.png" max-width="50px" />

                        <div class="telega-support mt-3 mb-5">
                            <v-tooltip
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <a
                                        style="text-decoration: none"
                                        href="https://t.me/Elena_Pahomova"
                                        target="_blank"
                                    >
                                        <v-btn
                                            fab
                                            dark
                                            small
                                            color="blue"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>fab fa-telegram-plane</v-icon>
                                        </v-btn>
                                    </a>
                                </template>
                                <span>Связаться в Telegram</span>
                            </v-tooltip>
                        </div>
                        <div class="mt-1" style="text-align: center;">
                            <p class="mb-1">Время работы техподдержки:</p>
                            <p class="mb-1">пн-пт с 10 до 17 мск,</p>
                            <p>сб, вс - выходной</p>
                        </div>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: "Instruments",
    data: () => ({
        
    }),
    computed: {
        ...mapGetters({
            profile: 'user/profile',
        }),
        profileData() {
            return this.profile
        },
    },
    methods: {
        scrollToMe () {
            let scrollBottom = document.getElementById("discount")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
    }
}
</script>

<style scoped>
.exp-panel-title .v-expansion-panel-header {
    font-size: 14px
}
.exp-panel-title .v-expansion-panel--active {
    border: 1px solid #69db69
}
.p-price {
    font-weight: bold
}
.p-price span {
    font-size: 18px;
    color: #8c0000
}
.ul-price {
    list-style-type: none
}
.p-red {
    color: red;
    font-size: 14px;
    line-height: 18px;
    margin-top: 10px;
}
.ul-price li {
    padding-bottom: 10px;
    line-height: 26px !important
}
.checkbox-price .v-label {
    color: #222
}
.dop-p-price {
    text-decoration: underline;
    margin-top: 30px
}
.row-instruments {
  max-width: 600px;
  margin: 0 auto;
}
.sum-p-price {
    font-weight: bold;
    margin-top: 30px
}
.sum-p-price span {
    font-weight: normal
}
.btn-main {
    padding: 17px 30px !important;
}
.h2-tarif {
    text-transform: uppercase;
    text-align: center;
    color: #333;
    font-size: 48px;
}
.p-dostup {
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10px;
}
.p-dostup-check {
    margin-bottom: 10px;
    line-height: 30px !important;
    text-transform: uppercase;
    font-weight: 700;
}
.p-dostup-check img {
    max-width: 25px;
    position: absolute;
}
.p-dostup-check span {
    padding-left: 45px;
    display: block;
    padding-top: 1px;
}
.block-one-year {
    position: relative;
    /* margin-top: 20px; */
    text-align: center;
}
.skobka-left {
    max-width: 24px;
    position: absolute;
    left: 10%;
    top: 10px;
}
.p-one-year {
  background-color: #333;
  display: inline-block;
  color: #fff;
  padding: 5px 15px;
  font-size: 24px;
  font-weight: normal;
  margin-top: -20px;
}
.p-cost-one-year-old {
    font-size: 28px;
    margin-top: 40px;
    position: relative;
}
.old-price-img {
    position: absolute;
    max-width: 70px;
    left: 30%;
}
.p-cost-one-year {
    font-size: 32px;
    font-weight: 500;
    margin-top: 50px;
}
.skobka-right {
    max-width: 24px;
    position: absolute;
    right: 10%;
    top: 10px;
}
.p-samiy {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 20px;
    background-color: #333;
}
.price-500 {
  font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}
.accent-img {
  max-width: 150px;
}
.p-oplata-mes {
    text-align: center;
    background-color: #333;
    display: inline-block;
    color: #fff;
    padding: 5px 15px;
    font-size: 24px;
    font-weight: normal;
    margin-top: -20px;
}
.p-price-oplata-old {
    position: relative;
    text-align: center;
    font-size: 28px;
    margin-top: 40px;
}
.old-price-img-oplata {
    position: absolute;
    max-width: 70px;
}
.p-price-oplata {
    font-size: 32px;
    text-align: center;
    font-weight: 500;
    margin-top: 50px;
}
.p-price-oplata-1000 {
  font-size: 28px;
    text-align: center;
    margin-top: 40px;
}
.p-oplata-vigoda {
    font-size: 18px;
    text-align: center;
    font-weight: 500;
}
.p-attention {
    color: #333;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
}
.p-open-dostup {
    text-align: center;
    font-size: 22px;
}
.telega-support {
    text-align: center;
}
.aloe-bottom-right {
    bottom: 0;
    right: 0;
    position: absolute;
    max-width: 150px;
}
    
@media(max-width: 600px) {
    .block-media-lk {
        padding: 0
    }
    .card-media-lk {
        padding: 0
    }
}
@media (max-width: 474px) {
    .p-price-oplata-old.mob, .p-oplata-vigoda.mob {
        display: none;
    }
    .p-oplata-mes {
        margin-top: 30px;
    }
    .p-price-oplata {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
</style>